:root { 
    --color_primary: #3a59af !important;
}
.justify-content-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.companies_table_spacing {
  margin-top: 30px !important;
}
.onboard_table_spacing {
  margin-top: 15px !important;
}

.companies_tab_active{
    color : var(--color_primary) !important;
}
.nav_active {
    border-bottom: 3px solid var(--color_primary) !important;
    color: var(--color_primary) !important;
  }
.button_cls {
    background-color: var(--color_primary) !important;
    
  }
  .table_background{
    background-color: #e2f5fc;
  }
  /* .table td.fit, 
.table th.fit {
    white-space: nowrap;
    width: 1%;
} */
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.save_button{
  background-color: #3a59af !important;
  border: none;
  color: white;
  border-radius: 4px;
  text-align: center;
  padding: 8px;
  padding-right: 22px;
  padding-left: 22px;
  margin-bottom: 10px;
}
/* .page_padding{
  padding: 1.2rem;
} */
.modal_background{
  background-color: rgb(216, 248, 244);
  border: 1px solid rgb(174, 214, 210);
  padding-left: 13px;
  border-radius: 5px !important;
}
.checklist_tag{
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 19px;
}
.checkbox_right{
  float: right;
}
.check{
  padding-left: 0 !important;
  padding-right: 4px !important;
  padding-top: 7px;
}
.icon-font{
  font-size: 16px;
  color: rgb(223, 222, 222);
  padding-right: 4px;
}
.box-text{
  color: rgb(223, 222, 222);
  font-size: 12px;
  margin-top: 8px;
}
.paragraph{
  color: rgb(247, 245, 245);
  font-size: 13px;
}
.company_text{
  color: white;
  font-weight: 500;
  font-size: 14px;
}
.moda_width{
  width: 650px !important;
}
.document-text{
  padding-top: 9px;
}
/* .bd-example-row {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(39,41,43,0.03);
  border: 1px solid rgba(39,41,43,0.1);
} */


.page-title-box {
  background-color:#f3f3f9 !important;
  box-shadow: none !important;
  padding: 10px 1.5rem;
  /* margin: auto !important; */
  margin-left:-28px !important;
  margin: -16px -1.5rem 1.5rem -1.5rem;
  
}
.button-width{
  /* width: 20% !important; */
  margin: -11px -1.5rem 1.5rem -1.5rem !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.left-icon{
  color: #33b1e0 !important;
  font-size: 19px;
  cursor: pointer;
}
.background-table{
  background-color: white !important;
  border-radius: 6px;
  border: none !important;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%) !important;
  border-style: none !important;
  margin-top: -17px !important;
}
tr.table_border_bottom {
  border-bottom: 1px solid #E6E6E6 !important;
}
thead, tbody, tfoot, tr, td, th {
  border-style: none !important;
}
.horizontal-line{
  color: rgb(150, 148, 148) !important;
}
.company_background{
  background-color: #16C6EE !important;
    /* border-radius: 5px !important; */
  padding: 18px;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%) !important;
  text-align: center;
} 

.details_background{
  background-color: #21bcdf !important;
  /* border-radius: 5px !important; */
  padding: 18px;
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%) !important;
}
.button_position{
  display: flex !important;
  justify-content: end !important;
  margin-bottom: 10px;
}
.button-disabled{
  background-color: #5d79c7 !important;
  color: #d0d2d3;
  border: none;
  border-radius: 4px;
  text-align: center;
  padding: 8px;
  padding-right: 22px;
  padding-left: 22px;
  margin-bottom: 10px;
}
.table_head_background_color{
  color:#5e5873!important ;
}
.table_border_bottom{
  color: #5E5873;
}

.button-hover:hover{
  background-color: #dddddd;
}
/* .input_box{
    margin: 0 auto;
    width: 140px;
    text-align: left;
} */

.dropzone-container{
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 3px dashed #ddd;
  min-height: 200px;
  align-items: center;
  /* margin: 20px auto; */
  cursor: pointer;
}
.upload_icon{
  font-size: 25px;
}
.inactive_icon {
  color: gray;
}
.card_heading{
  font-size:15px;
}
.fw_4{
  font-weight: 700;
}
.hidden {
  display: none;
}

.scroller{
  overflow-x:hidden
}
.notFound_page{
  text-align: center;
 }
 .btn_danger{
  background-color: hsl(300, 75%, 50%);
 }

.custom-modal-style {
  width: 400px !important;
  height: 450px !important;
} 
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input
{ 
  padding: 9.5px 14px !important;
}
.react-bs-table-sizePerPage-dropdown.dropdown .dropdown-menu { 
  top: auto;
  bottom: 100%;
}
.react-bs-table-sizePerPage-dropdown.dropdown .dropdown-menu>li>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  }

  /*Payment Information CSS*/
.custom-date-range {
  height: 39px;
  padding-right: 50px;
  border-color: rgba(0, 0, 0, .2);
}
  
.custom-date-range::placeholder {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
}
.calender-action-btns{
  right: 17px;
  top: 7px;
  font-size: 18px;
  position: absolute;
  gap: 1px;
} 
.calender-icon {
  cursor: pointer;
}
.payment-table .pagination{
  justify-content: end;
}

.payment-filter-fields .MuiAutocomplete-popper{
  z-index: 1001 !important;
}
  
.custom-month-header .numInputWrapper span.arrowUp , .numInputWrapper span.arrowDown{
  right: -5px;
}

.custom-month-header .flatpickr-current-month span.arrowUp::after{
  border-bottom-color: #fff;
}

.custom-month-header .flatpickr-current-month span.arrowDown:after  {
  border-top-color: #fff;
}

.custom-month-header .numInputWrapper span:hover {
  border: 1px solid #fff;
  opacity: .7;
}
 /*Company detail CSS*/
.hover-text:hover{
  color: rgb(12, 102, 236);
  cursor: pointer;
}

.payment-tabs .nav-tabs-custom .nav-link.active {
  background-color: white;
}

.payment-offcanvas{
  width: max-content !important;
  max-width: 60% !important;
}

.react-bootstrap-table.scroll-table {
  overflow: auto;
  display: block;
}
